<template>
  <div class="materials-page">
    <TopBlock
      :title="'Проекты'"
      :description="'Здесь вы можете познакомиться со всеми специальными проектами «АстраЗенека»'"
      :images="[
        {
          src: `https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/projects_375.jpg`,
          media: '(max-width: 767px)',
        },
        {
          src: `https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/projects_744.jpg`,
          media: '(max-width: 1220px)',
        },
        {
          src: `https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/projects_1440.jpg`,
          media: '(max-width: 1919px)',
        },
        {
          src: `https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/projects_1920.jpg`,
          media: '(max-width: 2559px)',
        },
        {
          src: `https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/projects_2560.jpg`,
          media: '(max-width: 3839px)',
        },
        {
          src: `https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/projects_3840.jpg`,
          media: '(min-width: 3840px)',
        },
      ]"
    />
    <Breadcrumbs :pages="[{ name: 'Проекты' }]" />
    <div class="filter">
      <div class="content">
        <div class="filter__row d-none d-md-grid" data-id="filterElem" v-if="!$root.isMobile">
          <Select
            
            :taggable="false"
            :push-tags="false"
            class="filter__item"
            v-model="therapeutic_areas"
            :items="therapeutic_areas_list"
            :placeholder="'Терапевтические области'"
            :multiple="true"
            @change="
              onFilterChange('Терапевтические области', therapeutic_areas_YM)
            "
          />
          <Select
           
            :taggable="false"
            :push-tags="false"
            class="filter__item"
            v-model="disease"
            :items="disease_list"
            :placeholder="'Нозология'"
            :multiple="true"
            @change="onFilterChange('Нозология', disease_YM)"
          />
        </div>
        <div class="filter__opener d-md-none" v-if="$root.isMobile" data-id="filterElem" @click="openFilter()">
          <span class="mr-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 18.95V17.45H9.175V18.95H3ZM3 6.55V5.05H13.325V6.55H3ZM10.675 21V15.375H12.175V17.45H21V18.95H12.175V21H10.675ZM7.675 14.8V12.75H3V11.25H7.675V9.15H9.175V14.8H7.675ZM10.675 12.75V11.25H21V12.75H10.675ZM14.825 8.625V3H16.325V5.05H21V6.55H16.325V8.625H14.825Z"
                fill="#3C4242"
              />
            </svg>
          </span>
          Отфильтровать
        </div>
        <div class="filter-mobile d-md-none" v-if="filterOpened">
          <div
            @click="closeFilter()"
            class="filter-mobile__back button button_empty-pink button_small"
          >
            <span class="mr-2"
              ><svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 1L1 6L6 11"
                  stroke="currentColor"
                  stroke-linecap="square"
                ></path></svg
            ></span>
            Назад
          </div>
          <div class="filter-mobile__title">{{ filterTitle }}</div>
          <div class="filter-mobile__items" v-if="selectedFilter === null">
            <div
              class="filter-mobile__item"
              @click="selectFilter(0, 'Терапевтические области')"
            >
               <span>Терапевтические области</span>
              <div class="filter-mobile__item-icon">
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6 6L1 11"
                    stroke="#3C4242"
                    stroke-linecap="square"
                  ></path>
                </svg>
              </div>
            </div>
            <div
              class="filter-mobile__item"
              @click="selectFilter(1, 'Нозология')"
            >
              <span>Нозология</span>
              <div class="filter-mobile__item-icon">
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6 6L1 11"
                    stroke="#3C4242"
                    stroke-linecap="square"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="filter-mobile__filter" v-if="selectedFilter === 0">
            <Checkbox
              v-for="(check, ind) in therapeutic_areas_list"
              :key="ind"
              :label="check.label"
              :inputValue="check.value"
              v-model="therapeutic_areas"
              class="mb-6"
            />
            <div class="filter-mobile__buttons">
              <div
                class="filter-mobile__accept button button_pink mb-3"
                @click="
                  onFilterChange(
                    'Терапевтические области',
                    therapeutic_areas_YM
                  )
                "
              >
                Применить
              </div>
              <div
                class="filter-mobile__accept button button_empty-pink"
                @click="clearFilter('therapeutic_areas')"
              >
                Очистить
              </div>
            </div>
          </div>
          <div class="filter-mobile__filter" v-if="selectedFilter === 1">
            <Checkbox
              v-for="(check, ind) in disease_list"
              :key="ind"
              :label="check.label"
              :inputValue="check.value"
              v-model="disease"
              class="mb-6"
            />
            <div class="filter-mobile__buttons">
              <div
                class="filter-mobile__accept button button_pink mb-3"
                @click="onFilterChange('Нозология', disease_YM)"
              >
                Применить
              </div>
              <div
                class="filter-mobile__accept button button_empty-pink"
                @click="clearFilter('disease')"
              >
                Очистить
              </div>
            </div>
          </div>
        </div>
        <div
          class="filter__current-filter"
          v-if="currentFilter && currentFilter.length"
        >
          <div
            class="filter__current-filter-item"
            v-for="(item, ind) in currentFilter"
            :key="ind"
          >
            <span v-html="item.label"></span>
            <span
              @click="removeFilterItem(item.type, item.value)"
              class="ml-2 filter-item__remove"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2.00012C6.49 2.00012 2 6.49012 2 12.0001C2 17.5101 6.49 22.0001 12 22.0001C17.51 22.0001 22 17.5101 22 12.0001C22 6.49012 17.51 2.00012 12 2.00012ZM15.36 14.3001C15.65 14.5901 15.65 15.0701 15.36 15.3601C15.21 15.5101 15.02 15.5801 14.83 15.5801C14.64 15.5801 14.45 15.5101 14.3 15.3601L12 13.0601L9.7 15.3601C9.55 15.5101 9.36 15.5801 9.17 15.5801C8.98 15.5801 8.79 15.5101 8.64 15.3601C8.35 15.0701 8.35 14.5901 8.64 14.3001L10.94 12.0001L8.64 9.70012C8.35 9.41012 8.35 8.93012 8.64 8.64012C8.93 8.35012 9.41 8.35012 9.7 8.64012L12 10.9401L14.3 8.64012C14.59 8.35012 15.07 8.35012 15.36 8.64012C15.65 8.93012 15.65 9.41012 15.36 9.70012L13.06 12.0001L15.36 14.3001Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </div>
          <div
            class="filter__clear button button_empty-pink button_small"
            @click="clearFilter()"
          >
            Сбросить
            <span class="ml-2">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.5 3.50012L12.5 12.5001"
                  stroke="currentColor"
                  stroke-linecap="square"
                />
                <path
                  d="M12.5 3.50012L3.5 12.5001"
                  stroke="currentColor"
                  stroke-linecap="square"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div v-if="Projects && Projects.length">
        <div class="materials-page__list" v-if="Projects && Projects.length">
          <Project
            v-for="i in materialsListLength"
              :item="{...Projects[i - 1], type: {name: 'Проекты'}}"
            :key="i"
            @watchLater="
              watchLater(
                Projects[i - 1].watch_later ? 'DELETE' : 'POST',
                Projects[i - 1].id,
                filter
              )
            "
            @favorite="
              favorite(
                Projects[i - 1].favorite ? 'DELETE' : 'POST',
                Projects[i - 1].id,
                filter
              )
            "
          />
        </div>
      </div>
      <div v-else-if="!Projects" class="preloader detail-page__preloader">
        <Preloader class="" />
      </div>
      <div v-else class="materials-page__empty">
        По выбранному фильтру нет материалов
      </div>
      <div class="materials-page__buttons">
        <div
          class="materials-page__load button button_pink"
          v-if="Projects && Projects.length > listLength"
          @click="loadMore()"
        >
          Загрузить ещё
          <span class="ml-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.5 12.7501L4.5 12.0001H3V12.7501H4.5ZM19.5 12.7501C19.5 16.8923 16.1421 20.2501 12 20.2501L12 21.7501C16.9706 21.7501 21 17.7207 21 12.7501L19.5 12.7501ZM12 20.2501C7.85786 20.2501 4.5 16.8923 4.5 12.7501H3C3 17.7207 7.02944 21.7501 12 21.7501L12 20.2501ZM12 5.25012C16.1421 5.25012 19.5 8.60799 19.5 12.7501L21 12.7501C21 7.77956 16.9706 3.75012 12 3.75012V5.25012ZM7.56864 6.69856C8.8101 5.78779 10.3412 5.25012 12 5.25012V3.75012C10.0114 3.75012 8.17143 4.39596 6.68136 5.48912L7.56864 6.69856Z"
                fill="#B36697"
              />
              <path
                d="M8.08391 1.05776L6.37094 6.61929L11.9325 8.33225"
                stroke="#B36697"
                stroke-width="1.5"
              />
            </svg>
          </span>
        </div>
        <div
          class="materials-page__up button button_empty-pink"
          @click="toTop()"
        >
          К началу страницы
          <span class="ml-2">
            <svg
              width="18"
              height="10"
              viewBox="0 0 18 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.25 8.75012L8.75 1.25012L16.25 8.75012"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="square"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "../../components/form-elements/Select.vue";
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
import TopBlock from "../../components/pageComponents/TopBlock.vue";
import Project from "../../components/pageComponents/Project.vue";
import Checkbox from "../../components/form-elements/Checkbox.vue";
import RadioButton from "../../components/form-elements/RadioButton.vue";
import { mapActions, mapGetters } from "vuex";
import filter from "@/mixins/filter";
import Preloader from "@/components/Preloader.vue";
import { bus } from "@/main";

export default {
  metaInfo: {
    title: "Проекты",
  },
  name: "Projects",
  mixins: [filter],
  components: {
    TopBlock,
    Breadcrumbs,
    Project,
    Select,
    Checkbox,
    RadioButton,
    Preloader,
  },
  data: () => ({
    filterTitle: "Фильтр",
    filterOpened: false,
    items: [],
    count: 8,
    listLength: 8,
  }),
  computed: {
    ...mapGetters(["user","NosologysFilter", "Projects", "DiseaseFilter"]),
    therapeutic_areas_list() {
      return this.NosologysFilter.map((el) => ({
        label: el.title,
        value: el.slug || el.id,
      }));
    },
    disease_list() {
      return this.DiseaseFilter.map((el) => ({
        label: el.title,
        value: el.slug || el.id,
      }));
    },
    materialsListLength() {
      return this.listLength > this.Projects.length
        ? this.Projects.length
        : this.listLength;
    },
    therapeutic_areas_YM() {
      let arr = [];
      this.therapeutic_areas.forEach((i) => {
        let find = this.therapeutic_areas_list.find((el) => el.value === i);
        if (find) {
          arr.push(find.label);
        }
      });
      return arr;
    },
    disease_YM() {
      let arr = [];
      this.disease.forEach((i) => {
        let find = this.disease_list.find((el) => el.value === i);
        if (find) {
          arr.push(find.label);
        }
      });
      return arr;
    },
  },
  methods: {
    ...mapActions(["fetchLibraryFilter", "fetchProjects", "fetchProjectWatchLater", "fetchProjectFavorite"]),
    toTop() {
      document.body.scrollIntoView({ behavior: "smooth" });
    },
    watchLater(method, id, filter) {
      this.fetchProjectWatchLater({ method: method, id: id, filter: filter });
    },
    favorite(method, id, filter) {
      this.fetchProjectFavorite({ method: method, id: id, filter: filter });
    },
    loadMore() {
      this.listLength =
        this.listLength + this.count > this.Projects.length
          ? this.Projects.length
          : this.listLength + this.count;
      //  this.$router.replace({ query: {...this.$route.query, page: this.listLength/this.count} }).catch(() => {});
    },
    onFilterChange(name, value) {
      const vm = this;
      if (typeof ym !== "undefined") {
        // ym(91468266, "reachGoal", "textmaterials page filter use", {
        //   "textmaterials page filter use": {
        //     "used filter": `${name} : ${value.join(",")}`,
        //     ...this.$root.ymFields,
        //   },
        // });
      }
      this.closeFilter();
    },
    clickText(name) {
      const vm = this;
      if (typeof ym !== "undefined") {
        // ym(91468266, "reachGoal", "textmaterials page click", {
        //   "textmaterials page click": {
        //     "textmaterials page name": name,
        //     ...this.$root.ymFields,
        //   },
        // });
      }
    },
  },
  created() {
    
  },
  async mounted() {
    
   if (
      this.user &&
      Object.keys(this.user).length &&
      typeof this.user === "object" &&
      ((this.user.flags && !this.user.flags.includes("filter")) ||
        !this.user.flags)
    ) {
    //   if(this.$root.isMobile) {
    //   this.filterOpened = true
    // }
        bus.$emit("startFilterHelp");
    }
    
    await this.fetchLibraryFilter();
    await this.fetchProjects(this.filter);
    if (this.$route.query && this.$route.query.page) {
      this.listLength = this.listLength * this.$route.query.page;
    }
  },
  watch: {
    therapeutic_areas() {
      this.buildQuery();
    },
    disease() {
      this.buildQuery();
    },
    filter() {
      this.fetchProjects(this.filter);
      this.listLength = this.count;
      let newQuery = { ...this.$route.query };
      delete newQuery.page;
      this.$router.replace({ query: { ...newQuery } }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.materials-page {
  &__empty {
    text-align: center;
    padding: 16px 0;
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
  }
  &__list {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 32px;
    row-gap: 32px;

    @media screen and (max-width: 1220px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 24px;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 24px;
    }
  }
  &__buttons {
    margin-top: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 1220px) {
      margin-bottom: 80px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
      flex-direction: column;
      align-items: stretch;
    }
  }
  &__load {
    width: 175px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  &__up {
    margin-left: auto;
    width: 213px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
}
.filter {
  margin-top: 64px;
  @media screen and (max-width: 1220px) {
    margin-top: 40px;
  }
  &__row {
    display: grid;
    column-gap: 16px;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: 1220px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 16px;
    }
  }

  &__item {
    max-width: none;
  }

  &__current-filter-item {
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    margin-right: 18px;

    & svg {
      color: #d9dada;
      transition: 0.3s;
    }

    &:hover {
      & svg {
        color: #830051;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__current-filter {
    margin-top: 18px;
    padding: 0 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  &__opener {
    padding: 0 19px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #3c4242;
    background: #f8f8f8;
    border: 1px solid #d8dada;
    border-radius: 999px;
  }

  &__clear {
    margin-bottom: 14px;
  }

  &-mobile {
    padding: 32px 16px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;

    &__buttons {
      position: absolute;
      bottom: 60px;
      width: 100%;
      left: 0;
      padding: 0 16px;
    }

    &__filter {
      max-height: calc(100vh - 310px);
      overflow-y: auto;
    }

    &__back {
      margin-bottom: 36px;
      width: 80px;
      height: 28px;
    }

    &__title {
      margin-bottom: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 29px;
      color: #1f1f1f;
    }

    &__item {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #3c4242;
    }
  }
}

.filter-item__remove {
  cursor: pointer;
}
</style>

<style>
.selectaria__select {
  max-width: none;
}
</style>